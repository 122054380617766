
.action-button-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    .delete-icon {
        cursor:pointer;
        height: 25px;
    
    
        &:hover {
            opacity: 0.6;
        }
    }
    
    .open-button {
        display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    border: 1px solid var(--neutral-gray-200, #D0D0D1);
    background-color: #fff;
    color: #222;

    &:hover {
        background-color: #F5F5F5;
    }
    
    }
}

.row-text {
    margin-top: 0rem;
}

.doc-title {
    font-size: 1.05rem;
    font-weight: 500;
}
// .doc-card {
//     width: 18rem;
//     margin: 1rem;
//     display:flex;
//     flex-direction: column;

//     img {
//         border-bottom: 1px solid #747474;
//         max-height:10rem;
//         background-size: cover;
//         background-position: center center;
//     }

//     .card-title {
//         font-size: 1.2rem;
//         font-weight: 700;
//     }

//     .action-row {
//         padding-top: 0.6rem;
//         display:flex;
//         flex-direction:row;
//         justify-content:space-between;
//         align-items:center;
//         .action-buttons {
//             padding: 0.3rem 0.3rem;
//             margin-left:0.3rem;
//             background-color: #fff;
//             border: 2px solid #713AFF;
//             .action-icons {
//                 height: 25px;
//                 border:none;
//             }

//             &:hover {
//                 opacity: 0.6;
//             }
//         }
        
//     }

//     .card-badge {
//         font-size: 0.8rem;
//         padding: 0.6rem;
//         margin: 0.6rem;
//         margin-left: 0;
//     }

//     .status-progress {
//         margin-top: 1.5rem;

//         .status-badge {
//             width:100%;
//             font-size: 1rem;
//             padding: 0.6rem;
//             margin: 0.6rem 0rem; 
//         }

//         .failed-status-badge {
//             width:100%;
//             font-size: 1rem;
//             padding: 0.6rem;
//             margin: 0.6rem 0rem; 
//             background-color: #D83A52 !important;
//         }
//     }

//     .card-button {
//         margin-top: 1.5rem;
//         background-color: #713AFF;
//         border: #713AFF;
//         font-weight: bold;
//         width: 100%;
//         &:hover {
//             background-color: #0060B9;
//         }
//     }
// }