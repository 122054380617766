.guardrails-section {
    display:flex;
    flex-direction:column;


    .full-width-input {
        padding-bottom: 1.5rem;
    }
}

.relevancy-panel {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
}
.handoff_toggle {
    margin-bottom: 1rem;
}