.insight-title-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;
}
.insights-filter { 
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    margin-bottom: 20px;
    padding: 0 20px;
    gap: 10px;

    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 10px 20px;

    .agent-select {
        width: 30rem;
        max-width: 100%;
    }

    .date-select {
        width: 20rem;
        max-width: 100%;
    }
}

.no-data-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 20px 20px;
    font-size: 1.5rem;
    font-weight: 500;
}

.data-panel {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    min-height: 8rem;
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .panel-body {
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .panel-title {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }
    }

}

.activity-over-time-panel {
    max-height: 50rem;
}
// .p3 {
//     max-height: 300px;
// }