.save-bar {
    border-top: 1px solid #EDEDED;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1rem 0.5rem;
    width: calc(100% - 268px); 
    /* Adjust marginLeft to match the width of your sidebar */
    margin-left: 268px;
    background-color: #EDEDED;

    .save-row {
        // padding-left:10%;
        display:flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
    
        .save-buttons {
            margin-right: 1rem;
            margin-left:1rem;
        }
    }
}