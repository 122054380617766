.route-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 40px;
}

.integration-action-panel {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}