.relevance-view { 
    margin-bottom: 4rem;

    .base-table {
        width: 100%;
        border-collapse: separate;       // this allows the radius to apply
        border-spacing: 0 0.2rem;        // little spacing between rows
        
        tr {
            background-color: #f9f9f9;  // light zebra striping for readability
            border-radius: 2px;
        }
        
        tr:nth-of-type(even) {
            background-color: #fff;
        }
        
        th, td {
            border: none;
            padding: 0.75em 1em;      // comfortable padding
            text-align: left;
            
        }

        th {
          background-color: #1E1E1F;
          color: #fff;
        }

        tr:hover {
          background-color: #e8e8e8;  // hover effect to give a bit of interactivity
        }
    }
}

.relevance-form {
    padding-bottom: 1rem;
}

.relevance-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}