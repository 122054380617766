.widget-preview {
  margin-bottom: 31px;
  display: flex;
  flex-direction: column;

  p {}
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #152536;
  margin: 0;
  margin-bottom: 17px;
}

.widget-wrap {
  background: #f2f2f2;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  position: relative;
}

.widget {
  width: 750px;

  @media screen and (max-width: 1180px) {
    width: 95%;
  }
}
