.footer {
    padding: 5rem 0;
    text-align:center;

    p {
        font-size: 1rem;
        font-style: italic;
        color:gray;

        a {
            font-weight: 700;
            color: gray;

            &:hover {
                color: adjust-hue($color: gray, $degrees: +180);
            }
        }
    }
}