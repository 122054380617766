
.sources-mb-3 {
    display: grid;
    
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;     // Adjust this to change the space between the items
  
    // Making the form checks full width will let grid handle the layout
    .form-check {
      width: 100%;
    }
  }