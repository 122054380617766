.behavior-form-section {
    margin-bottom: 2rem;
    
    .title-div {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

    .form-section-title {
        margin-bottom: 0.25rem;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .section-explantion {
        font-size: 0.9rem;
        font-weight: 400;
        color: #222;
    }
    }

    .full-width-input {
        padding-bottom: 1.5rem;
    }

    .section-two-col-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;

        .section-two-col {
            width: 48%;
        }
    }

    .md-textarea {
        min-height: 5rem;
    }
    .row.align-items-center {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .vert-terms {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
        }
      
        // Make the button smaller than the inputs
        .btn {
          max-width: 200px; // The maximum width you want the button to be
          white-space: nowrap; // Prevent the button's text from wrapping to multiple lines
          overflow: hidden; // Hide any overflowing text
          text-overflow: ellipsis; // Add an ellipsis (...) if the text is too long to fit in the button
        }
      }
 
}