.confirm-modal-wrap {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
}
.confirm-modal {
  background: white;
  width: 606px;
  padding: 20px 29px;
  background: #f5f5f5;
  border: 1px solid #000000;
  border-radius: 8px;

  .confirm-modal-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    width: 100%;

    .confirm-modal-heading {
      // font-family: "Merriweather";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #000000;
      margin: 0;
    }
    .confirm-modal-close-icon {
      opacity: 0.6;
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
}
.confirm-modal-body {
  display: flex;
  flex-direction: column;
}
.confirm-modal-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #152536;
  margin: 0;
  margin-bottom: 30px;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirm-btn {
  background: #1f62a5;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  height: 50px;
  font-family: "Inter", sans-serif;
  transition: 0.3s all;
  width: 186px;
  margin-right: 20px;

  &:hover {
    opacity: 0.8;
  }
}
.cancel-btn {
  margin-left: 20px;
}
