.conversation-history {
    padding: 1.5rem;
    max-width: 100%;
    margin: 0 auto;

    .conversation-select-title {
        padding-top: 1.5rem;
        text-align:center;
    }

    .conversation-table {
        margin-top: 2rem;

        tr {
            background-color: #f9f9f9;  // light zebra striping for readability
            border-radius: 2px;
        }
        
        tr:nth-of-type(even) {
            background-color: #fff;
        }
        
        th, td {
            border: none;
            padding: 0.75em 0.5em;      // comfortable padding
            text-align: left;
            
        }

        th {
          background-color: #1E1E1F;
          color: #fff;
        }

        tr:hover {
          background-color: #e8e8e8;  // hover effect to give a bit of interactivity
        }

        .column-md-1 {
            width: 8%; /* Adjust based on your needs */
          }
          
          .column-md-2 {
            width: 15%; /* Adjust based on your needs */
          }

        .conversation-table-row {
            display:flex;
            flex-direction:row;
            align-items: center;
            justify-content:space-between;
            padding: 1rem 0;
            font-weight: 500;
        }
    }
    .info-extract {
        padding: 5px 2px;
        border-radius: 3px;
        margin: 5px 0;
        display:block;
        text-align:center;
        font-size: 0.9rem;
        background-color: #f5f5f5;

        &:hover {
            opacity: 0.6;
        }
    }

    .table-header {
        font-weight: 500;
    }

    .no-conversations {
        text-align:center;
        padding-top: 1.5rem;
        font-size: 1.3rem;
        font-style:italic;
    }
}

.current-convo-select-menu {
    width: 800px !important;
    margin: 0 auto;
  
    @media screen and (max-width: 900px) {
      width: 80% !important;
    }
  }

  .current-convo-select {
    width: 800px !important;
    margin: 0 auto;
  
    @media screen and (max-width: 900px) {
      width: 100% !important;
    }
  }
  
  .convos-select {
    width: 100%;
  }

.conversation-overlay {
    padding-top: 7rem;
}

.conversation-overlay-body {
    padding-bottom: 5rem;
    font-size: 1.4rem;
    .context-info {
        // width: 100%;
        // display:flex;
        // flex-direction:column;
        // align-items:center;
        // justify-content:center;

        display:flex;
        padding-right: 10px;
        flex-direction:column;
        align-items: flex-start;
        width: 100%;
        justify-content:flex-start;
        margin: 0 10px 10px 0;

        font-size: 0.9rem;
        color: grey;
        font-weight: 500;

        .context-message {
            // text-align:center;
            font-size: 0.8rem;
            color: grey;
            font-weight: 500;
        }
    }

    p {
        margin-bottom: 0;
    }

    .user-message {
        padding: 10px 15px 10px 15px;
        border-radius: 8px;
        margin: 10px 20px 10px 0px;
        display:block;
        text-align:center;
        font-size: 1.0rem;
        background-color: #f5f5f5;
        text-align:left;
        font-weight: 500;
    }
    
    .assistant-message {
        padding: 10px 15px;
        border-radius: 8px;
        margin: 10px 0px 5px 20px;
        display:block;
        text-align:center;
        font-size: 1.0rem;
        background-color: #f5f5f5;
        // color: #fff;
        font-weight: 500;
        text-align:left;

        code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
              monospace;
            background: #0d1117;
            padding: 0.75px 6px;
            border-radius: 6px;
            color: #c9d1d9;
          }
        
          pre code {
            display: block;
            overflow-x: auto;
            padding: 1em;
            color: #c9d1d9;
            background: #0d1117;
            border-radius: 8px;
          }

        .sources {
            display:flex;
            flex-direction:row;
            justify-content:flex-start;
            align-items: center;
            margin: 10px 0 0 0;
            flex-wrap: wrap;
        }
        .source-button {
            margin: 2.5px 2.5px 5px 5px;
            padding: 5px 8px;
            color: #222;
            background-color: #f5f5f5;
            border: 1px solid #0883fe65;
            &:hover {
                background-color: adjust-hue($color: #0883fe65, $degrees: -20);
            }
        }
    }

    .reaction-info {
        display:flex;
        padding-right: 10px;
        flex-direction:column;
        align-items: flex-end;
        width: 100%;
        justify-content:flex-end;
        margin: 0 10px 10px 0;

        font-size: 0.8rem;
        color: grey;
        font-weight: 500;
    }
}

.container ul {
    list-style-type: none !important;
}