.knowledge-base {
    padding: 1.5rem;
    max-width: 90%;
        margin: 0 auto;
    .title-row {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
    }
    .workspace-content {
        padding-top: 2rem;
    }
    .text-link {
        font-weight: 600;

        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
    .action-panel {
        display:flex;
        flex-direction:row;
        justify-content:center;
        // .open-button {
        //     margin-right: 1.5rem;

        //     background-color: #3A0CA3;
        // border: #3A0CA3;
        // &:hover {
        //     background-color: adjust-hue($color: #3A0CA3, $degrees: -60) !important;
        // }
        // }

        .delete-button {
            background-color: #f56c6c;
            border-color: #f56c6c;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .new-button {
        background-color: #3A0CA3;
        border: #3A0CA3;
        &:hover {
            background-color: adjust-hue($color: #3A0CA3, $degrees: -60) !important;
        }
    }

    .no-collections {
        text-align:center;
        font-size: 1.3rem;
        font-style: italic;
        padding: 2rem;
        color: grey;

        a {
            color: grey;
            font-weight: bold;

            &:hover {
                opacity: 0.6;
            }
        }
    }

}