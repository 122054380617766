.new-crawl-settings {
    display:flex;
    flex-direction:row;
    justify-content:space-between;

    .crawl-setting-column {
        width: 49%;
    }
}

.new-crawl-button {
    float:right;
}

.crawl-breadcrumbs {
    
    @media only screen and (max-width: 1200px) {
        display: none;
    }
}