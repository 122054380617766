.no-docs {
    text-align:center;
    font-size: 1.3rem;
    font-style: italic;
    padding: 2rem;
    color: grey;
}

// .collection-row {
//     padding: 1.5rem 0rem;
//     margin: 1.5rem 0rem;
//     border-top: 0px solid #000;
//     .collection-header {
//         display:flex;
//         flex-direction:row;
//         justify-content:space-between;
//         align-items:center;

//         @media only screen and (max-width: 675px) {
//             flex-direction:column;
//             // margin: 0 auto;
//         }

//         div { 
//             display:flex;
//             flex-direction:row;
//             align-items:center;

//             @media only screen and (max-width: 675px) {
//                 flex-direction:column;
//                 text-align:center;
//                 // margin: 0 auto;
//             }

//             div {
//                 display:block;
//             }

//             h5 {
//                 font-size: 1rem;
//                 margin-bottom: 0;
//                 padding-bottom:0;
//                 color: grey;
//                 display:flex;
//                 flex-direction:row;
//                 @media only screen and (max-width: 675px) {
//                     justify-content: center;
//                    }
//             }

//             h3 {
//                 margin-bottom:0;
//             }

//             .request-button {
//                 background-color: #3A0CA3 !important;
//                 border: #3A0CA3 !important;
//                 margin-left: 1.5rem;
//                 &:hover {
//                     background-color: adjust-hue($color: #3A0CA3, $degrees: -60) !important;
//                 }

//                 @media only screen and (max-width: 675px) {
//                     margin-left: 0;
//                     margin-top: 0.5rem;
//                 }
//             }
//         }

//         .collection-buttons {
//             margin-right: 0.5rem;

//             @media only screen and (max-width: 675px) {
//                 margin-top: 0.5rem;
//             }
//         }

//         .new-button {
//             background-color: #3A0CA3 !important;
//             border: #3A0CA3 !important;
//             &:hover {
//                 background-color: adjust-hue($color: #3A0CA3, $degrees: -60) !important;
//             }
//             @media only screen and (max-width: 675px) {
//                 margin-top: 0.5rem;
//             }
//         }
//     }

//     .no-docs {
//         text-align:center;
//         font-size: 1.3rem;
//         font-style: italic;
//         padding: 2rem;
//         color: grey;
//     }

//     .doc-card-container {
//         display:flex;
//         flex-direction:row;
//         justify-content:flex-start;
//         align-items:flex-start;
//         flex-wrap:wrap;

//         @media only screen and (max-width: 675px) {
//             justify-content: center;
//         }
//     }
// }