.empty-state-container {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    padding: 50px 0; // Significant vertical padding
    text-align: center; // Centering text and icons

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .headline {
      font-size: 24px;
      margin: 20px 0;
    }
    .explanatory-text {
      font-size: 16px;
      margin-bottom: 20px;
    }
    .empty-open-button {
        margin: 0 auto;
    }
}