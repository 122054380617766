body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.InputField {
  margin-top: 15px;
  margin-bottom: 15px;
}

.container {
  max-width:100%;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: calc(50% + 134px);  /* centers the spinner and accounts for sidebar width */
  // transform: translate(-50%, -50%);  /* shifts the spinner's own dimensions */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // margin-right: -50%;
}

.main-content {
  margin-left: 268px; /* width of the sidebar */
  width: calc(100% - 268px); /* remaining width */
  /* Add other styles as needed (padding, etc.) */
}

// .primary-button {
//   background-color: #713AFF;
//   border: #713AFF;
//   padding: 0.5rem 1rem;
//   font-size: 1rem;
//   color: white;
//   font-weight: 600;
//   border-radius: 5px;
//   cursor: pointer;

//   &:hover {
//     background-color: #0060B9;
//     border: #0060B9;
//   }
// }

h2 {
  font-weight: 700;
}

.primary-button {
  display: flex;
padding: 12px 20px;
font-weight: 700;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background:  #6100D0;
color: #fff;
border: none;

img {
  width: 22px;
  height: 22px;
}

&:hover {
  background: #713AFF;
}
}

.secondary-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px 20px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background:  none;
  border: 1px solid #D0D0D1;
  color: #000;

  img {
    width: 22px;
    height: 22px;
    // margin-right: 8px;
  }

  &:hover {
    opacity: 0.7;
    color: #000;
    border: 1px solid #D0D0D1;
    background-color: #F5F5F5;
  }
}

.open-button {
  display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 10px;
border: 1px solid var(--neutral-gray-200, #D0D0D1);
background-color: #fff;
color: #222;

&:hover {
  background-color: #F5F5F5;
  color: #222;
  border: 1px solid var(--neutral-gray-200, #D0D0D1);
}

}

.embed-button {
  display: flex;
padding: 8px 12px;
font-size: 0.7rem;
justify-content: center;
align-items: center;
gap: 6px;
font-weight: 600;
border-radius: 10px;
border: 1px solid var(--neutral-gray-200, #D0D0D1);
background-color: #fff;
color: #222;

&:hover {
  background-color: #F5F5F5;
  color: #222;
  border: 1px solid var(--neutral-gray-200, #D0D0D1);
}
}

.delete-button {
  background-color: #6100D0;
  padding: 12px 20px;
  border: #D83A52;
  font-size: 1rem;
  color: white;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  margin: 0 0.5rem;

  &:hover {
    opacity: 0.7;
  }
}

.positive-button {
  background-color: #258750;
  border: #258750;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 0.5rem;

  &:hover {
    background-color: #007038;
    border: #007038;
  }
}

// .secondary-button {
//   background-color: #747474;
//   border: #747474;
//   padding: 0.5rem 1rem;
//   font-size: 1rem;
//   color: white;
//   font-weight: 600;
//   border-radius: 5px;
//   cursor: pointer;
//   margin: 0 0.5rem;

//   &:hover {
//     background-color: #C1C1C1;
//     border: #C1C1C1;
//   }
// }

.source-button {
  background-color: #747474;
  border: #747474;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 0.5rem;

  &:hover {
    background-color: #C1C1C1;
    border: #C1C1C1;
  }

  @media only screen and (max-width: 800px) {
    display: block;
}
}

a {
  color: #713AFF;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: #0060B9;
  }
}

.form-context {
  display:flex;
  justify-content:space-between;
  align-items: center;
}