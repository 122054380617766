// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.widget-configuration {
  padding: 24px;
  width: 100%;
  margin-bottom: 6rem;
}
.widget-configuration-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .heading {
    // font-family: "Merriweather";
    font-style: normal;
    font-weight: 600;
    font-size: 31px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;

    .heading {
      margin-bottom: 16px;
      font-size: 26px;
      line-height: 30px;
    }
  }
}
.create-widget-btn {
  background: #1f62a5;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  height: 50px;
  font-family: "Inter", sans-serif;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }
}
.current-widget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  .heading {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    color: #152536;
    margin-bottom: 14px;
  }
}
.select {
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  // width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
  height: 38px;
  // font-family: "Merriweather";

  &:hover {
    background: white;
    opacity: 0.8;
    color: black;
  }
}

.select-menu {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.current-widget-select-menu {
  width: 800px !important;
  // margin: 0 auto;

  @media screen and (max-width: 900px) {
    width: 80% !important;
  }
}

.select-item {
  display: flex;
  padding: 0;

  & > a {
    width: 100%;
    padding: 4px 16px;
    color: inherit;
    text-decoration: none;
  }
}
.select-item-ind {
  // font-family: "Merriweather";
  padding: 4px 16px;
}

.type-preview-wrap {
  display: grid;
  grid-template-columns: 1fr 3fr;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}
.configuration-item {
  margin-bottom: 10px;
}
.configuration-item-label {
  // font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #152536;
  margin: 0;
  margin-bottom: 10px;
}
.configuration-item-select {
  width: 100%;
}
.form-input {
  height: 38px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  // font-family: "Merriweather";

  &:focus {
    box-shadow: none;
    border: 1px solid #0a58ca;
  }
  &:hover {
    border: 1px solid #0a58ca;
  }
}
.preview {
  padding-left: 53px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.current-widget-select {
  width: 800px !important;
  margin: 0 auto;

  @media screen and (max-width: 900px) {
    width: 100% !important;
  }
}

.widgets-select {
  width: 100%;
}

.widget-loading {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-mobile {
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
    position: sticky;
    z-index: 2;
    top: 20px;
  }
}
.embed-mobile {
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
}
