.template-form-section {
    .title-div {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

    .form-section-title {
        margin-bottom: 0.25rem;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .section-explantion {
        font-size: 0.9rem;
        font-weight: 400;
        color: #222;
    }
    }
    .section-two-col-row {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;

        .section-two-col {
            width: 48%;
        }
        .template-save-button {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
    .template-textarea {
        min-height: 20rem;
        padding-bottom: 0.5rem;

    }

}

.macro-threshold-field {
    max-width: 400px;
    margin-top: 2.5rem;
}