.switch {
  margin-bottom: 10px;
}
.switch-label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #152536;
  margin: 0;
  margin-bottom: 10px;
}

.switch-wrap {
  display: flex;
  align-items: center;
}
.start-label {
  margin-right: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
}
.end-label {
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
}
