.profile-container {
    padding-top: 2.5rem;
    margin: 0 auto;
    max-width: 40rem;
    padding-bottom: 4rem;
    .profile-setting-row {
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    div.list-group-item {
        background-color: #fff !important;
        display:flex;
        flex-direction: row;
        align-items: center;
        padding: 0;

        .logout-row {
            display:flex;
            flex-direction: row;
            align-items: center;
            
            span {
                padding-right: 0.4rem;
            }
        }
        p {
            font-size: 1.2rem;
            padding:1rem;
            height: 100%;
            margin: 0;

            .profile-button {
                border-radius: 10px;
                border: 1px solid var(--neutral-gray-200, #D0D0D1);
                background-color: #fff;
                color: #222;
                font-weight: 600;
                
                &:hover {
                background-color: #F5F5F5;
                color: #222;
                border: 1px solid var(--neutral-gray-200, #D0D0D1);
                }
            }
        }
    }

    h2 {
        padding-top: 2rem;
        padding-bottom: 0rem;
    }
}