.knowledge-base {
    padding: 1.5rem;
    max-width: 90%;
    margin: 0 auto;
    .title-row {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        padding-bottom: 2rem;
    }
    .text-link {
        font-weight: 600;

        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }

    .agent-content {
        margin-top: 2rem;
    }
    .action-panel {
        display:flex;
        flex-direction:row;
        justify-content:center;

        .settings-button {
            margin-right: 1rem;
            margin-left:1rem;
        }

        .delete-button {
            margin-left: 0;
        }
    }
    .no-collections {
        text-align:center;
        font-size: 1.3rem;
        font-style: italic;
        padding: 2rem;
        color: grey;

        a {
            color: grey;
            font-weight: bold;

            &:hover {
                opacity: 0.6;
            }
        }
    }

}