.breadcrumbs {
    padding-left: 3rem;
    padding-top: 1.5rem;

    @media only screen and (max-width: 1200px) {
        display: none;
    }
}

.page-body {
    display:flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    // background-color: blue;
    width:calc(100% - 268px);
    height: 100%;
    .Centered-Form {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20rem;
        text-align:center;

        max-width: 100%;
        // background-color: yellow;
    
        @media only screen and (max-width: 675px) {
            width: 100%;
        }
    
        .form-context {
            margin: 0 0.75rem;
    
            .info-badge {
                // background-color: #C1C1C1 !important;
                border: 1px solid #C1C1C1 !important;
                font-weight: 400;
                cursor: pointer;
                color: #222222;
    
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        .webpage-add-form {
            width: 50rem;
            text-align:left;
    
            .page-type-dropdown {
                max-width: 10rem;
            }
        }
    
        .new-doc-form {
            text-align:left;
            padding-top: 1rem;
            max-width: 30rem;
            margin: 0 auto;
    
    
            .option {
                margin-bottom: 2rem;
            }
        }
    
        .upload-button {
        
            margin-top: 2rem;
            
        }
        
        .status-alert {
            margin: 1.5rem auto;
            max-width: 30rem;
    
            .status-message {
                padding-top:1rem;
                padding-bottom:0rem;
                text-align:left;
    
                a {
                    color: #0c4128;
                    font-weight: bold;
                }
            }
        }
    }
}

.doc-support-overlay {
    padding-top: 7rem;
}