.widget-embed-code {
  flex-shrink: 0;
}
.heading-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #152536;
    margin: 0;
  }
}
.widget-embed-copy-btn {
  background: #1f62a5;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  transition: 0.3s all;
  width: 205px;
  height: 35px;
  flex-shrink: 0;

  &:hover {
    opacity: 0.8;
  }
}
.code-wrap {
  padding: 18px 22px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  // font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #000000;
  background-color: #f2f2f2;
  white-space: pre-wrap;
  position: relative;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.overlay {
  display: flex;
  position: absolute;
  inset: 0;
  background-color: rgba($color: #fff, $alpha: 0.6);
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
}

.widget-embed-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}
.widget-embed-heading {
  margin-bottom: 3px !important;
}
.widget-embed-para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  margin: 0;
}
