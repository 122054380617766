.docpage {
    max-width: 100%;
    margin: 0;
    padding: 0;
    
    .chat-panel-column {
        padding: 0;
        height: 92vh;
        width: 33%;
        margin: 0;
        padding: 0;
        position: absolute;

        @media only screen and (max-width: 800px) {
            width: 100%;
        }
    }
}

.doccolumn {
    margin-left: 33% !important;
    max-width: 100%;
    margin: 0;
    display:flex;
    flex-direction:column;

    @media only screen and (max-width: 800px) {
        display:none;
    }

    .highlighted {
        background-color: #fbec5d !important;
        font-weight: 600 !important;
    }

    .html-text {
        padding: 1rem 2rem;
        background-color: #fff !important;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    }
    .html-file {
        background-color: #fff !important;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        padding: 0.5rem 0.5rem;
        max-width: 100%;
    }

    .form-context {
        display:flex;
        justify-content:space-between;
        align-items: center;
    }
}

#preview {
    padding: 15px !important; 
}

.docpage_header {
    padding: 15px;
    position: fixed;
}

.docpage_header strong {
    font-size: 16px;
}
.category {
    padding: 5px;
    margin: 5px;
    font-size: 16px;
}

.css-17f081g {
    width: 100% !important;
}

.doctitlebar {
    padding: 15px;
    display:flex;
    justify-content:space-between;

    .doc-selector {
        width:60% !important;

        .option {
            width: 100%;
        }
    }

    .view-selector {
        width:38%;
        
        .option {
            width: 100%;
        }
    }
}

.toggle_button {
    float:right;
    display:block !important;
}