.title-area {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding-bottom: 2rem;
    padding-top: 5rem;
}

.crawl-status-row {
    display:flex;
    flex-direction:row;
    justify-content:space-between;

    .crawl-status-column {
        width: 49%;
    }
}

.crawl-status-message {
    margin: 1.5rem auto;
    font-size: 1.5rem;
    text-align:center;
}

.ticket-sync { 
    padding: 1.5rem;
    width: 90%;
    margin: 0 auto;

}