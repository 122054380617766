.doc-title-row {
    padding-bottom: 1rem;
    padding-top: 0rem;

    .title-area {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        // padding-bottom: 2rem;
        padding-bottom: 0.5rem;
        padding-top: 1.5rem;
    
        h2 {
            font-size: 2.5rem;
            font-weight: 700;
        }
        
        img {
            max-height: 4rem;
            min-width: 6rem;
            margin-right: 1.5rem;
            border-radius: 4px;
            box-shadow: 0 0 0 0px rgba(0,0,0,.1);
        }
    }
}

.doc-fields-container {
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: flex-start;
    padding-bottom: 5rem;

    .active-column {
        width: 66%;
        
        .section-text-column {
            border: 1px solid #D0D0D1;
            border-radius: 5px;
            padding: 1rem 0.5rem;

            .section-text {
                padding: 0 0.5rem;
                border-radius: 5px;
                &:hover {
                    background-color: #F4F0FF;                
                }
            }
        }
        

        .question-field {
            margin-bottom: 1rem;

            textarea {
                min-height: 2rem;
                height: auto;
            }
        }

        .form-buttons {
            display:flex;
            flex-direction:row;
            justify-content:flex-end;

            .discard {
                background-color: #f5f5f5;
                color: #3A0CA3;
                border: 1px solid #3A0CA3;

                &:hover {
                    background-color: adjust-hue($color: #f5f5f5, $degrees: -60);
                    color: adjust-hue($color: #3A0CA3, $degrees: -60);
                }
            }

            .save {
                background-color: #3A0CA3;
                border: 1px solid #3A0CA3;
                margin-left: 1rem;

                &:hover {
                    background-color: adjust-hue($color: #3A0CA3, $degrees: -60);
            }
        }
        }
    }

    .inactive-column {
        width: 32%;

        .doc-link-separator {
            a {
                padding-right: 0.5rem;
            }
        }
    }
}