.modal-save-bar {
    border-top: 1px solid #EDEDED;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 1rem 0.5rem;
    width: auto; // Let the container control the size.
    margin-left: 0; // No left-margin since this is in a modal now.
    background-color: #EDEDED;

    .save-row {
        display:flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        padding: 0;

        .save-buttons {
            margin-right: 1rem;
            margin-left:1rem;
        }
    }
}