.sidebar-menu {
    width: 268px;
    height: 100vh;
    background: #1e1e1f;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;

    .inner-sidebar {
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .logo {
      display: flex;
      align-items: flex-start;
      padding: 5px;
      border-bottom: 1px solid #444546;
      // Add logo styles here
      // background: url(image.png); etc.

      .sidebar-logo {
        max-width: 80%;
        padding: 1rem;
        // margin: 0 auto;
      }
    }

    .nav-icon {
        width: 20px;
        height: 20px;
    }
  
    .sidebar-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        gap: 12px;
      
        width: 100%;
        height: 48px;
        margin: 0.5rem 0;
      
        background: #1E1E1F;
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #86878A;
        &:hover {
          color: #fff;
        }

        .wrapper {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0px;
          padding-left: 0.5rem;
          gap: 6px;
      
          height: 40px;
      
          .icon {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 2px;
            gap: 8px;
      
            width: 30px;
            height: auto;
      
            border-radius: 12px;
      
            img {
              margin: 0 auto;
              width: auto;
              height: auto;
            }

        }



        .content-label {
            width: 100%;
            height: 24px;
        
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
          }
    }

      &:hover {
        // Add any additional hover styles here
        // color: #fff;
        // opacity: 0.7;
        background-color: #6100D0;

        // .content-label {
        //     color: #fff;
        // }
      }
    }

    .sidebar-item.active {
        background-color: #6100D0;
        padding: 0.8rem 1.2rem;
        // margin: 0.25rem 0.5rem;
        // border-radius: 8px;
        color: white;
        text-decoration: none;
        font-weight: 500;

        .content-label {
          color: #fff;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
  }
.user-profile {
  display: flex;
  align-items: center;
  padding: 16px;
  border-top: 1px solid #444546;
  .avatar {
    width: 40px;
    height: 40px;
    background: #6100D0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 18px;
    margin-right: 12px;

    a {
      color: #FFFFFF;
      font-weight: 500;
      font-size: 18px;
    }
  }
  .user-name {
    color: #fff;
    font-size: 18px;

    a {
      color: #fff;
      font-size: 16px;
    }
  }
}
