.select-wrap {
  margin-bottom: 10px;
}
.select-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #152536;
  margin: 0;
  margin-bottom: 10px;
}
.configuration-item-select {
  width: 100%;
}

.select {
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
  height: 38px;
  // font-family: "Merriweather";

  &:hover {
    background: white;
    opacity: 0.8;
    color: black;
  }
}
.select-disabled {
  background-color: #e9ecef !important;
  border-color: rgb(206, 212, 218) !important;
  opacity: 1 !important;
  color: #000 !important;
}
.select-menu {
  width: 100%;
}

.select-dd:hover {
  .select-disabled {
    border-color: #0a58ca !important;
  }
}
