.form-section {
    margin-bottom: 2rem;

    .title-div {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

    .form-section-title {
        margin-bottom: 0.25rem;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .section-explantion {
        font-size: 0.9rem;
        font-weight: 400;
        color: #222;
    }
    }

    .section-two-col-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;

        .section-two-col {
            width: 48%;
        }
    }

    .md-textarea {
        min-height: 5rem;
    }

 
}