.small-screen-overlay {
    position: fixed;   /* Position the overlay fixed within the viewport. */
    top: 0;            /* Align the overlay at the top edge. */
    bottom: 0;         /* Align the overlay at the bottom edge. */
    left: 0;           /* Align the overlay at the left edge. */
    right: 0;          /* Align the overlay at the right edge. */
    z-index: 9999;     /* Use a high z-index to ensure the overlay is on top of other content. */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.95); /* Use RGBA for a slightly transparent black background. */
    color: white;
    padding: 40px;
    text-align: center;
    width: 100%;
    height: 100%;
}