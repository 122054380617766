.knowledge-base {
    padding: 1.5rem;
    width: 90%;
    margin: 0 auto;

    .knowledge-bases-content {
        margin-top: 2rem;
    }
    
    .title-row {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items: center;
    }
    .text-link {
        font-weight: 600;

        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }

    .base-table {
        width: 100%;
        border-collapse: separate;       // this allows the radius to apply
        border-spacing: 0 0.2rem;        // little spacing between rows
        
        tr {
            background-color: #f9f9f9;  // light zebra striping for readability
            border-radius: 2px;
        }
        
        tr:nth-of-type(even) {
            background-color: #fff;
        }
        
        th, td {
            border: none;
            padding: 0.75em 1em;      // comfortable padding
            text-align: left;
            
        }

        th {
          background-color: #1E1E1F;
          color: #fff;
        }

        tr:hover {
          background-color: #e8e8e8;  // hover effect to give a bit of interactivity
        }
    }

    .action-panel {
        display:flex;
        flex-direction:row;
        justify-content:space-around;
        gap: 12px;
        // .open-button {
        //     margin-right: 1.5rem;

        //     background-color: #3A0CA3;
        // border: #3A0CA3;
        // &:hover {
        //     background-color: adjust-hue($color: #3A0CA3, $degrees: -60) !important;
        // }
        // }

        .delete-icon {
            padding: 8px 10px;
            &:hover {
                opacity: 0.6;
                cursor: pointer;
            }
        }
    }

    .new-button {
        background-color: #3A0CA3;
        border: #3A0CA3;
        &:hover {
            background-color: adjust-hue($color: #3A0CA3, $degrees: -60) !important;
        }
    }

    .no-collections {
        text-align:center;
        font-size: 1.3rem;
        font-style: italic;
        padding: 2rem;
        color: grey;

        a {
            color: grey;
            font-weight: bold;

            &:hover {
                opacity: 0.6;
            }
        }
    }

}