.import-option-card {
    min-width: 200px;
    margin: 0.5rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .import-option-image {
        max-width: 30%;
        margin-top: 8px;
    }
    .import-option-body {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .import-option-title {
        font-size: 1.2rem;
        font-weight: bold;
        color: #222;
        text-align:center;

    }

    &:hover {
        background-color: #eee;
    }
}