.cache {
    padding: 1.5rem;
    width: 90%;
    margin: 0 auto;

    .modal-save-bar {
        border-top: 1px solid #EDEDED;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        padding: 1rem 0.5rem;
        width: auto; // Let the container control the size.
        margin-left: 0; // No left-margin since this is in a modal now.
        background-color: #EDEDED;
    
        .save-row {
            display:flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            padding: 0;
    
            .save-buttons {
                margin-right: 1rem;
                margin-left:1rem;
            }
        }
    }

    .cache-buttons {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items: center;

        .script-item-button {
            margin: 0 auto;
        }
    }

    .scripted-item-fields {
        margin: 2rem 0;
        display:flex;
        flex-direction:column;
        gap: 1rem;
    }

    .scripted-item-answer {
        min-height: 10rem;
    }
}

