.bot-settings-container {
    padding: 1.5rem;
    margin-bottom: 4.5rem;

    .title-area {
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
    }

    .nav-tabs {
        border-bottom: 1px solid #D0D0D1;
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        li.nav-item {
          // background-color: #fff;
          &:active {
            background-color: #fff;
          }
          &:hover {
            background-color: #fff;
          }
        }
        .nav-item {
          border: 1px solid #D0D0D1;
          background-color: #f5f5f5;
          border-radius: 5px 5px 0 0;
        //   margin-right: 0.2rem;
          width: calc(100% / 5.1);
    
          .nav-link {
            color: #222;
            font-size: 1rem;
            font-weight: 700;
            padding: 0.5rem 1rem;
            width: 100%;

            &:hover {
              color: adjust-hue($color: #3A0CA3, $degrees: -60);
            }

            &:active {
                color: #3A0CA3;
            }
          }
        }
    }

    .tab-content {
        border: 1px solid #D0D0D1;
        background-color: #fff;
        padding: 1rem;
        border-radius: 0px 0 5px 5px;
    
        p {
          margin: 0;
        }
      }
}