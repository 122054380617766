.collection-chat-container {
    width: 60%;
    left: 20%;
    position: fixed;
    height: 85%;

    @media only screen and (max-width: 800px) {
        width: 100%;
        left:0%;
    }
    h6 {
        padding-top: 1.5rem;
        padding-left: 2rem;
    }
    h2 {
        padding-top: 0rem;
        padding-left: 2rem;
    }
}