.container {
    .drop-container {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 3rem;
        max-width: 30rem;
        text-align: center;
        background-color: #fff;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            background-color: #eee;
        }
    }

    .file-tracker {
        margin-top: 1rem;
        max-height: 10rem;
        overflow: auto;
    }

    h5 {
        padding-top: 1.3rem;
        text-align: left;
        font-size: 1rem;
    }

    ul {
        text-align: left;
        list-style-type: circle;
    }
}