.tags {
    padding: 1.5rem;
    width: 90%;
    margin: 0 auto;

}

.workflow-title {
    font-weight: 600;
}

.tag-buttons {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    gap: 8px;
}

.tag-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}