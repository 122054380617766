.add-widget-modal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
}
.modal-widget {
  background: white;
  width: 606px;
  padding: 40px 29px;
  background: #f5f5f5;
  border: 1px solid #000000;
  border-radius: 8px;

  .widget-modal-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    width: 100%;

    .widget-modal-heading {
      // font-family: "Merriweather";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #000000;
      margin: 0;
    }
    .widget-modal-close-icon {
      opacity: 0.6;
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
}
.widget-modal-body {
  display: flex;
  flex-direction: column;
}
.add-widget-btn {
  margin-top: 17px;
  align-self: flex-end;
  position: relative;
  width: 186px;
}
