.tag-view { 
    margin-bottom: 2rem;
}

.tag-fields-container {
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: flex-start;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0rem;
    .tag-item-fields {
        margin: 0rem 0;
        margin-bottom: 2rem;
        display:flex;
        flex-direction:column;
        gap: 2rem;

        .form-context {
            .form-control {
                min-height: 3rem;
            }
            
            .tag-options {
                min-height: 3rem;
            }
        }
    }

    
    .tags-active-column {
        width: 62%;
        padding-top: 0;
        margin-top: 0;
        .form-context {

        .tag-description {
            min-height: 6rem;
        }
        
        .tag-options {
            min-height: 3rem;
        } }
    }

    .inactive-column {
        width: 34%;
    }

    textarea.form-control {
        min-height: 6rem;
    }
}

