.pageHandler {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;

    /* custom-pagination.css */

/* Active state */
.pagination .active .page-link {
    background-color: #6100D0; /* Change this color to your desired active background color */
    border-color: #6100D0; /* Change this color to your desired active border color */
    color: #ffffff; /* Change this color to your desired active text color */
  }

  .pagination .page-link {
    color: #6100D0;
  }
  
  /* Hover state */
  .pagination .page-link:hover {
    background-color: #6100D0; /* Change this color to your desired active background color */
    border-color: #6100D0; /* Change this color to your desired active border color */
    color: #ffffff; /* Change this color to your desired active text color */
  }
  
  /* Disabled state */
  .pagination .disabled .page-link {
    background-color: #f8f9fa; /* Change this color to your desired disabled background color */
    border-color: #dee2e6; /* Change this color to your desired disabled border color */
    color: #adb5bd; /* Change this color to your desired disabled text color */
  }
  
}