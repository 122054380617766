.topics-form-section {
    margin-bottom: 2rem;

    .title-div {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

    .form-section-title {
        margin-bottom: 0.25rem;
        font-size: 1.3rem;
        font-weight: 600;
    }

    .section-explantion {
        font-size: 0.9rem;
        font-weight: 400;
        color: #222;
    }
    }
    .suggested-q-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 1.5rem 0;
    }
}