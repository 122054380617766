.bot-cache {
    width: 100%;

    .cache-title {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        margin-bottom: 1rem;
    }

    .cache-buttons {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items: center;
    }

    .cache-explanation {
        padding-bottom: 1rem;
    }

    .base-table {
        width: 100%;
        border-collapse: separate;       // this allows the radius to apply
        border-spacing: 0 0.2rem;        // little spacing between rows
        
        tr {
            background-color: #f9f9f9;  // light zebra striping for readability
            border-radius: 2px;
        }
        
        tr:nth-of-type(even) {
            background-color: #fff;
        }
        
        th, td {
            border: none;
            padding: 0.75em 1em;      // comfortable padding
            text-align: left;
            
        }

        th {
          background-color: #1E1E1F;
          color: #fff;
        }

        tr:hover {
          background-color: #e8e8e8;  // hover effect to give a bit of interactivity
        }
    }
}
.variant-modal .modal-dialog {
    max-width: 75%;  // Or either increase the percentage to make it wider or set to a fixed width, e.g., 800px
  }
.new-cache-field {
    padding-bottom: 1.5rem;

    .cache-textarea {
        min-height: 4rem;
    }
}