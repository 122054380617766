.agent-table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    }

.base-table {
    @media screen and (max-width: 1500px) {
        td .action-panel {
            gap: 0.2rem;

            flex-direction: column;
            justify-content: center;
            align-items: center;
        }        
    }
}
