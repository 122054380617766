
h6 {
    margin-bottom: 0;
}
.base-buttons {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items: center;
    width: 15rem;
    gap: 0.5rem;

    .quick-text {
        width: 90px;
    }
}

.action-panel {
    .action-delete {
        margin: 0 0.5rem !important;
    }
}

.base-section-title {
    text-align:center;
    margin: 1.5rem;
}

.mb-3 .quick-add-text {
    min-height: 20rem;
}