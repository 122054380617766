.faqContainer {
    text-align:center;
    padding-top: 2.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0 auto;
    max-width: 50rem;
    a {
        font-weight: 700;
        color: #000;
    }

    .accordian-header {
        font-weight: 700;
        
    }

    .accordion {
        text-align:left;
        padding-top: 1.5rem;
        

        .header {
            background-color: aqua;
        }
    }
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #ffffff;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}