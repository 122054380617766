.collection-chat-panel-container {
    align-items: center;
    justify-content: space-between;
    width: 60%;
    left: 20%;
    height: inherit;
    position: fixed !important;
    margin-top: .5rem;

    @media only screen and (max-width: 800px) {
        width: 100%;
        left: 0;
    }

    .message-container {
        height: 90%;
        position: relative;
        z-index: 1;
        width: 100%;
        left: 0;
        overflow-y: scroll;
        margin: 0 !important;
        padding: 0 !important;
        padding-top: 0.2rem;
        padding-bottom: 12rem;
        padding-right: 0;

        @media only screen and (max-width: 800px) {
            &::-webkit-scrollbar {
                width: 0;
            }
        }
        
        .question-container {
            // display: flex;
            // flex-direction: column;
            // align-items: center !important;

            position: fixed;
            width: 59%;
            // left: 0;
            // width: 32%;
            // bottom: 12%;
            bottom: 150px;

            @media only screen and (max-width: 800px) {
                width: 100%;
                
            }

            .h5-suggested-questions {
                text-align:center;
            }

            .question {
                padding: 1rem;
                margin: 1rem 1rem;
                padding-bottom: 1rem;
                background-color: #EDEDED;
                border-radius: 1rem;
                text-align:Center;

                &:hover {
                    cursor: pointer;
                    opacity: 0.6;
                }
            }
        }

        .inner-message-container {
            display: "flex";
            flex-direction: "column";
            justify-content: "flex-end";
            width: 100%;
            padding-bottom: 6rem;

            &::-webkit-scrollbar {
                width: 0.3rem;
            }

            .message {
                padding: 1rem;
                margin: 0 1rem;
                padding-bottom: 1rem;
                background-color: white;
                border-radius: 1rem;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            }

            .answer {
                padding: 1rem;
                margin: 0 1rem;
                padding-bottom: 1rem;
                background-color: #EDEDED;
                border-radius: 1rem;
                box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

                .expand-snippet {
                    cursor: pointer;
                    text-decoration-line: underline;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    .input-container {
        position: fixed;
        left: 20%;
        width: 60%;
        bottom: 0;

        @media only screen and (max-width: 800px) {
            width: 100%;
            left: 0%;
        }

        .input-box {
            color: #000;
            background-color: #fff;
            padding: 1.5rem;
            width: 100%;
            z-index:5;
            justify-content: center;

            .MuiOutlinedInput-root {
                z-index: 12 !important;
                color: #000 !important;
                font-size: 1.2rem !important;
                left: 0;
                background-color: #fff;
            }
        }

        .send-icon {
            &:hover {
                cursor: pointer;
            }
        }
    }

}

.MuiBox-root css-hpgf8j {
    padding: 0 !important;
    margin: 0 !important;
}

.sourceButton {
    padding: 2px 5px 2px 5px;
    float:right;
    font-size:0.8rem;
    margin: 5px;
    margin-top:10px;

    @media only screen and (max-width: 800px) {
        display: none;
    }
}

.evidence-section {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    font-size: 0.9rem;

    .evidence-text {
        max-Width: 85%;
        font-size: 0.7rem;

        @media only screen and (max-width: 800px) {
            max-width:100%;
        }
        h6 {
            font-size: 0.75rem;
            font-weight: 600;
            padding-left: 0;
            padding-top:0;
        }
        p {
            padding: 0;
            font-size:0.75rem;
            padding-bottom: 0.2rem;
            margin: 0;
        }
    }
}