.Centered-Form {
  position: absolute;
  left: calc(50% + 268px / 2);
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .center-logo {
    padding: 2rem;
    max-width: 20rem;
  }
  .signup-link {
    padding-left: 150px;
  }
  .create-account-link {
    color: #000;

    padding-left: 100px;
    &:hover {
      opacity: 60%;
    }
  }
}